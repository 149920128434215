module.exports = {
  GTM_ID: 'GTM-T353HWZ',
  // GTM_HOST_ORIGIN: 'https://gtm.lylli.de', // Not supported in gatsby-config.js
  ICON: '🇩🇪',
  SITE_URL: 'https://account.lylli.de',
  CHECKOUT_URL: 'https://checkout.lylli.de/v2/de/',

  SUPPORT_MAIL: 'kontakt@lylli.de',

  CURRENCY: '€',
  STORELOGOS: {
    APPLE: {
      alt: 'App Store logo',
      href: 'https://apps.apple.com/de/app/id1036395238',
      imageIdSuffix: '-app-store-image',
      linkIdSuffix: '-app-store-link',
    },
    GOOGLE: {
      alt: 'Google Play logo',
      href: 'https://play.google.com/store/apps/details?id=se.boksnok.android&hl=de&gl=US',
      imageIdSuffix: '-google-play-image',
      linkIdSuffix: '-google-play-link',
    },
    AMAZON: {
      alt: 'Amazon App Store logo',
      href: 'https://www.amazon.de/dp/B0CV9ZX795',
      imageIdSuffix: '-amazon-app-store-image',
      linkIdSuffix: '-amazon-app-store-link'
    }
  },
  PRICE: {
    MONTHLY: '6,90',
    YEARLY: '55'
  },
  SUBSCRIPTION : {
    TYPES: {
      WEEKLY: 'Wochenabonnement',
      MONTHLY: 'Monatsabonnement',
      QUARTERLY: 'Quartalsabonnement',
      YEARLY: 'Jahresabonnement'
    },
    TYPES_SHORT : {
      WEEKLY: 'Woche',
      MONTHLY: 'Monat',
      QUARTERLY: 'Quartal',
      YEARLY: 'Jahr'
    },
    DURATION: {
      YEARLY: 'Jahr',
      MONTHLY: 'Monat'
    },
    SINGULAR : {
      WEEKLY: 'Woche',
      MONTHLY: 'Monat',
      QUARTERLY: 'Quartal',
      YEARLY: 'Jahr',
    },
    PLURAL : {
      WEEKLY: 'Wochen',
      MONTHLY: 'Monate',
      QUARTERLY: 'Quartale',
      YEARLY: 'Jahre',
    },
  },
  MONTHS: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
};
